import ApiService from "@/common/api.service";
import {
  LOGIN,
  LOGOUT,
  REGISTER_ACCOUNT_EVENTKEY,
  CHECK_AUTH,
  SET_PASSWORD_AUTHCODE,
  CHECK_AUTHCODE,
  RC_LOGOUT,
  GET_CONFIG,
  GET_CHECKININFO
} from "./actions.type";
import {
  HIDE_FLOATY, SET_AUTHCODE_RESULT, SET_LIVECHATENABLED,
  SET_AUTH, PURGE_AUTH, SET_ERROR, SET_PASSWORD_SUCCESS,
  UNSET_ERROR, SET_CONFIG, SET_CONFIGUPDATEDAT, SET_CHECKININFO,
  SET_UNREADCHATROOMS
} from "./mutations.type";

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  eventConfig: {},
  liveChatEnabled: false,
  isVerified: null,
  needPassword: null,
  isValidAuthCode: null,
  configUpdatedAt: null,
  userUpdatedAt: null,
  checkinCode: null,
  checkinAt: null,
  unreadChatRooms: null,
  offline: false,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  eventConfig(state) {
    return state.eventConfig;
  },
  isVerified(state) {
    return state.isVerified;
  },
  liveChatEnabled(state) {
    return state.liveChatEnabled;
  },
  needPassword(state) {
    return state.needPassword;
  },
  isValidAuthCode(state) {
    return state.isValidAuthCode;
  },
  configUpdatedAt(state) {
    return state.configUpdatedAt;
  },
  userUpdatedAt(state) {
    return state.userUpdatedAt;
  },
  checkinCode(state) {
    return state.checkinCode;
  },
  checkinAt(state) {
    return state.checkinAt;
  },
  unreadChatRooms(state) {
    return state.unreadChatRooms;
  },
  offline(state) {
    return state.offline;
  }

};

const actions = {
  [LOGIN](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/login?lang=" + payload.lang, { user: payload.credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [SET_PASSWORD_AUTHCODE](context, payload) {
    const { authCode, newPassword, newPasswordVerify } = payload;
    const user = {
      authCode, newPassword, newPasswordVerify
    };
    return new Promise(resolve => {
      ApiService.post("auth/setPasswordWithAuthCode", user)
        .then(({ data }) => {
          context.commit(SET_PASSWORD_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CHECK_AUTHCODE](context, payload) {
    const { authCode } = payload;
    const user = {
      authCode
    };
    return new Promise(resolve => {
      ApiService.post("auth/checkAuthCode", user)
        .then(({ data }) => {
          context.commit(SET_AUTHCODE_RESULT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    ApiService.get("auth", "logout")
      .then(({ data }) => {
      })
      .catch(({ response }) => {
        //context.commit(SET_ERROR, response.data.errors);

      });
  },
  [REGISTER_ACCOUNT_EVENTKEY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/createAccountEventKey?lang=" + payload.lang, { user: payload.credentials })
        .then(({ data }) => {
          if (typeof data.user != "undefined") {
            // If login information are sent on create account, login immediately.
            // Used for anonymous login.
            context.commit(SET_AUTH, data.user);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_CONFIG](context) {
    ApiService.get("auth", "getConfig")
      .then(({ data }) => {
        context.commit(SET_CONFIG, data.config);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [GET_CHECKININFO](context) {
    ApiService.get("auth", "getCheckinInfo")
      .then(({ data }) => {
        context.commit(SET_CHECKININFO, data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [CHECK_AUTH](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getWParam("auth/ping", {
        ...params, "configUpdatedAt": context.state.configUpdatedAt,
        "userUpdatedAt": context.state.userUpdatedAt,
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          context.commit(SET_CONFIGUPDATEDAT, data)
          context.commit(SET_UNREADCHATROOMS, data.unreadChatRooms)
          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          context.commit(PURGE_AUTH);
          resolve();
        });
    })
  },
  offline(context) {
    context.commit('setOffline');
  },
  online(context) {
    context.commit('setOnline');
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [UNSET_ERROR](state) {
    state.errors = {};
  },
  [SET_AUTH](state, user) {
    state.user = user;
    state.errors = {};
    state.isAuthenticated = true;
  },
  [SET_AUTHCODE_RESULT](state, data) {
    state.isVerified = data.isVerified;
    state.needPassword = data.needPassword;
    state.isValidAuthCode = data.isValidAuthCode;
  },
  [SET_CONFIG](state, config) {
    state.eventConfig = config;
  },
  [SET_CHECKININFO](state, config) {
    state.checkinCode = config.checkin_code;
    state.checkinAt = config.checkin_at;
  },
  [SET_LIVECHATENABLED](state, data) {
    state.liveChatEnabled = data;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.configUpdatedAt = null;
    state.user = {};
    state.errors = {};
  },
  [SET_PASSWORD_SUCCESS](state, value) {
    state.setPasswordSuccess = value;
  },
  [SET_CONFIGUPDATEDAT](state, value) {
    if (state.configUpdatedAt !== value.configUpdatedAt) {
      state.eventConfig = value.config;
      state.configUpdatedAt = value.configUpdatedAt;
    }
  },
  [SET_UNREADCHATROOMS](state, value) {
    state.unreadChatRooms = value;
  },
  setOnline(state) {
    state.offline = false;
  },
  setOffline(state) {
    state.offline = true;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
