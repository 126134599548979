import Vue from "vue";
import Vuex from "vuex";

import home from "./home.module";
import auth from "./auth.module";
import participants from "./participants.module";
import profile from "./profile.module";
import videoplayer from "./videoplayer.module";
import rc from "./rc.module";
import rocketchat from "./rocketchat.module"; // Todo: merge rc with rocketchat module someday.
import rooms from "./rooms.module";
import calendar from "./calendar.module";
import webinars from "./webinars.module";
import usereditform from "./usereditform.module";
import pages from "./pages.module";
import menu from "./menu.module";
import notification from "./notification.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    auth,
    participants,
    profile,
    videoplayer,
    rc,
    rocketchat,
    rooms,
    calendar,
    usereditform,
    webinars,
    pages,
    menu,
    notification,
  }
});
