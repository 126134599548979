import Vue from "vue";
import store from "@/store";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.withCredentials = true;
  },

  errorHandler(error) {
    console.log(error);
    if (error.response) {
      // Response returned an error (401, 404, 500)
      if (error.response.status >= 500) {
        store.dispatch('pushToast', { text: error.message + '\n' + error.response.statusText, title: "Error", type: "error" });
      }
    }
    else if (error.request) {
      // no response from server (offline)
      store.dispatch('pushToast', { text: error.message, title: "Error", type: "error" });
    } else {
      // some other error
      store.dispatch('pushToast', { text: error.message, title: "Error", type: "error" });
    }
    throw error;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => this.errorHandler(error));
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => this.errorHandler(error));
  },

  getWParam(resource, params) {
    // remove all values that are "null"
    Object.keys(params).forEach((k) => params[k] == null && delete params[k]);

    return Vue.axios.get(resource + "?" + new URLSearchParams(params)).catch(error => this.errorHandler(error));
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch(error => this.errorHandler(error));
  },

  postDl(resource, params) {
    return Vue.axios.post(`${resource}`, params, { responseType: 'blob' }).catch(error => this.errorHandler(error));
  },

  postFile(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).catch(error => this.errorHandler(error));
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => this.errorHandler(error));
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(error => this.errorHandler(error));
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => this.errorHandler(error));
  }
};

export default ApiService;

export const RCService = {
  get(slug) {
    return ApiService.get("rc", slug);
  }
}

export const ParticipantsService = {
  query(type, params) {
    return ApiService.query("participants" + (type === "all" ? "/list" : ""), {
      params: params
    });
  },
  get(slug) {
    return ApiService.get("participants/get", slug);
  },
};

