<template>
  <div id="app">
    <RwvHeader />
    <router-view style="padding-top: 60px"></router-view>

    <RwvFloatingRocketChat />
    <RwvFloatingVideo />

    <RwvFooter />

    <div :style="{ 'height': floatyDisplay && floaty ? '300px' : '0px' }"></div>
  </div>
</template>

<script>
import RwvHeader from "@/components/TheHeader";
import RwvFooter from "@/components/TheFooter";
import RwvFloatingVideo from "@/components/FloatingVideoPlayer";
import RwvFloatingRocketChat from "@/components/FloatingRocketChat";
import Sidebar from "@/components/Sidebar";
import {
  RC_LOGOUT,
  GET_CONFIG,
  FETCH_MENU,
  FETCH_MENU_PUBLIC,
  CHECK_AUTH,
} from "@/store/actions.type";
import { HIDE_FLOATY } from "@/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    RwvHeader,
    RwvFooter,
    RwvFloatingVideo,
    RwvFloatingRocketChat,
  },
  computed: {
    ...mapGetters([
      "embedUrl",
      "x",
      "y",
      "width",
      "height",
      "floaty",
      "floatyDisplay",
      "isAuthenticated",
      "eventConfig",
      "currentUser",
      "activeNotification",
    ]),

    isNotHome() {
      return !(this.$route.name === "Home");
    },

    lang() {
      return this.$i18n.locale;
    },
  },

  data() {
    return { firstRun: true, trackfunc: null };
  },
  mounted() {
    this.trackfunc = setInterval(this.tracking, 60000);
  },
  methods: {
    tracking: function () {
      this.$store.dispatch(CHECK_AUTH, {
        site: this.$route.path,
        site_from: null,
        is_video_visible: this.$store.getters.floatyDisplay,
        video_url: this.$store.getters.embedUrl,
        visibility_state: document.visibilityState,
        session_string: sessionStorage.getItem("sessionKey"),
      });
    },
    langVal: function () {
      if (this.$i18n.locale == this.eventConfig.lang1) return 1;
      if (this.$i18n.locale == this.eventConfig.lang2) return 2;
      if (this.$i18n.locale == this.eventConfig.lang3) return 3;
      return 0;
    },
    getContrast: function (hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }

      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor
          .split("")
          .map(function (hex) {
            return hex + hex;
          })
          .join("");
      }

      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);

      // Get YIQ ratio
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      // Check contrast
      return yiq >= 128 ? "black" : "white";
    },
    pSBC: function (p, c0, c1, l) {
      let r,
        g,
        b,
        P,
        f,
        t,
        h,
        i = parseInt,
        m = Math.round,
        a = typeof c1 == "string";
      if (
        typeof p != "number" ||
        p < -1 ||
        p > 1 ||
        typeof c0 != "string" ||
        (c0[0] != "r" && c0[0] != "#") ||
        (c1 && !a)
      )
        return null;
      if (!this.pSBCr)
        this.pSBCr = (d) => {
          let n = d.length,
            x = {};
          if (n > 9) {
            ([r, g, b, a] = d = d.split(",")), (n = d.length);
            if (n < 3 || n > 4) return null;
            (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))),
              (x.g = i(g)),
              (x.b = i(b)),
              (x.a = a ? parseFloat(a) : -1);
          } else {
            if (n == 8 || n == 6 || n < 4) return null;
            if (n < 6)
              d =
                "#" +
                d[1] +
                d[1] +
                d[2] +
                d[2] +
                d[3] +
                d[3] +
                (n > 4 ? d[4] + d[4] : "");
            d = i(d.slice(1), 16);
            if (n == 9 || n == 5)
              (x.r = (d >> 24) & 255),
                (x.g = (d >> 16) & 255),
                (x.b = (d >> 8) & 255),
                (x.a = m((d & 255) / 0.255) / 1000);
            else
              (x.r = d >> 16),
                (x.g = (d >> 8) & 255),
                (x.b = d & 255),
                (x.a = -1);
          }
          return x;
        };
      (h = c0.length > 9),
        (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
        (f = this.pSBCr(c0)),
        (P = p < 0),
        (t =
          c1 && c1 != "c"
            ? this.pSBCr(c1)
            : P
              ? { r: 0, g: 0, b: 0, a: -1 }
              : { r: 255, g: 255, b: 255, a: -1 }),
        (p = P ? p * -1 : p),
        (P = 1 - p);
      if (!f || !t) return null;
      if (l)
        (r = m(P * f.r + p * t.r)),
          (g = m(P * f.g + p * t.g)),
          (b = m(P * f.b + p * t.b));
      else
        (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
          (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
          (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
      (a = f.a),
        (t = t.a),
        (f = a >= 0 || t >= 0),
        (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
      if (h)
        return (
          "rgb" +
          (f ? "a(" : "(") +
          r +
          "," +
          g +
          "," +
          b +
          (f ? "," + m(a * 1000) / 1000 : "") +
          ")"
        );
      else
        return (
          "#" +
          (
            4294967296 +
            r * 16777216 +
            g * 65536 +
            b * 256 +
            (f ? m(a * 255) : 0)
          )
            .toString(16)
            .slice(1, f ? undefined : -2)
        );
    },
  },
  watch: {
    activeNotification(newValue) {
      try {
        this.$notification[newValue.type](this.$t(newValue.text), { title: this.$t(newValue.title), infiniteTimer: true, showCloseIcn: true });
      } catch {
        this.$notification.new(this.$t(newValue.text), { title: this.$t(newValue.title) });
      }
    },
    isAuthenticated(newValue) {
      if (newValue == false) {
        this.$store.commit(HIDE_FLOATY);
        this.$router.push("/");
        this.$store.dispatch(RC_LOGOUT);
      }
    },
    lang(newValue) {
      if (
        this.eventConfig.titleLang2 === "" ||
        this.eventConfig.titleLang2 == null
      ) {
        this.eventConfig.titleLang2 = this.eventConfig.titleLang1;
      }
      if (
        this.eventConfig.titleLang3 === "" ||
        this.eventConfig.titleLang3 == null
      ) {
        this.eventConfig.titleLang3 = this.eventConfig.titleLang1;
      }

      this.$primevue.config.locale.accept = this.$t('accept');
      this.$primevue.config.locale.reject = this.$t('reject');



      var post = " | vi-meet";

      if (this.langVal() == 1)
        document.title = this.eventConfig.titleLang1 + post;
      if (this.langVal() == 2)
        document.title = this.eventConfig.titleLang2 + post;
      if (this.langVal() == 3)
        document.title = this.eventConfig.titleLang3 + post;
    },
    eventConfig(newValue) {
      this.$i18n.fallbackLocale = newValue.lang1;
      if (this.firstRun) {
        this.firstRun = false;
        if (this.currentUser.lang) {
          this.$i18n.locale = this.currentUser.lang;
        } else {
          this.$i18n.locale = newValue.lang1;
        }
      }
      var r = document.querySelector(":root");
      r.style.setProperty("--primary", newValue.colorPrimary);
      r.style.setProperty(
        "--primaryheadline",
        this.pSBC(-0.7, newValue.colorPrimary)
      );
      r.style.setProperty(
        "--primarytext",
        this.getContrast(newValue.colorPrimary)
      );
      r.style.setProperty(
        "--primaryhover",
        this.pSBC(-0.2, newValue.colorPrimary)
      );
      r.style.setProperty("--secondary", newValue.colorSecondary);
      r.style.setProperty(
        "--secondarytext",
        this.getContrast(newValue.colorSecondary)
      );
      r.style.setProperty(
        "--secondaryhover",
        this.pSBC(-0.2, newValue.colorSecondary)
      );
    },
  },
};
</script>

<style>
:root {
  --primaryhover: rgb(0, 0, 0);
}

#videoSidebar {
  width: 100px !important;
  position: sticky;
  top: 0;
}

.notificationCenter {
  padding-top: 60px;
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--primarytext) !important;
}

.btn-primary:hover {
  background-color: var(--primaryhover) !important;
  border-color: var(--primaryhover) !important;
}

.btn-secondary {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: var(--secondarytext) !important;
}

.btn-secondary:hover {
  background-color: var(--secondaryhover) !important;
  border-color: var(--secondaryhover) !important;
}

.my-bg-primary {
  background-color: var(--primary) !important;
  color: var(--primarytext) !important;
}

.my-bg-secondary {
  background-color: var(--secondary) !important;
  color: var(--secondarytext) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
  color: var(--primarytext) !important;
}

.videoSidebarContent {
  position: sticky;
  top: 0;
  width: 400px;
}

[class^="ri-"],
[class*=" ri-"] {
  vertical-align: top;
}

a.router-link-exact-active,
a.router-link-active-exact {
  /*color: #960505 !important;*/
  color: var(--primarytext) !important;
  background: var(--primary) !important;
}

a.router-link-active-exact .p-menuitem-text {
  /*color: #960505 !important;*/
  color: var(--primarytext) !important;
}

.navbar .p-menuitem-icon {
  display: none;
}
</style>
